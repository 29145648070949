
  import { defineComponent, onMounted, ref, watch } from 'vue';
  import axios, { AxiosError } from 'axios';
  import useAlert from "@/composables/Alert"
  import router from '@/router';
  import { campHandleBrPhoneNumber, campHandleCPF, campHandleEmailVerification, campHandleEveryoneIsTrue, handleCreditCardNumber } from '@/composables/DataValidation';
  import {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useAuthStore } from '@/store/AuthStore';
  import { useCompanyStore } from '@/store/CompanyStore';
  
  interface IUserData {
    cpf: string,
    name: string,
    email: string,
    tel: string,
    moderator: boolean,
    consultant: boolean,
    birth_date: string,
    card_number: string | null,
    card_pin: string | null,
    idStore: [],
    id_role?: number | null
  }
  
  interface IUserResponse {
    data: {
      error: boolean,
      message: string,
      data: IUserData | null
      errorCode: string
    },
    status: number
  }
  
  export default defineComponent({
    name: "UserPost",
    components: {
      CampHeader,
      CampFormHeader,
      CampFormRackSubmitBtn
    },
    setup() {
      const authStore = useAuthStore()
      const initData: IUserData = {
        cpf: "",
        name: "",
        email: "",
        tel: "",
        moderator: false,
        consultant: true,
        birth_date: "",
        card_number: null,
        card_pin: null,
        idStore: [],
      }
      const companyStore = useCompanyStore()
      const storeOptions: any = ref([])
      const postObject = ref<{
        isLoading: boolean,
        noError: boolean,
        data: null | IUserData
      }>({
        isLoading: false,
        noError: true,
        data: { ...initData }
      })
      const initUserAppCPF = {
        data: null,
        isLoading: false,
        available: false,
      }
      const userAppCPF = ref<{
        data: IUserData | null
        isLoading: boolean
        available: boolean
      }>({ ...initUserAppCPF })
      const fieldNameRef = ref<HTMLElement | null>(null)
  
      /** Warning of unfilled fields */
      const isRequiredField = ref(false)
      const isRequiredNameComplete = ref(false)
      const isInvalidFieldWarning = ref(false)
      const isInvalidField = ref<{ 
        cpf: boolean,
        mail: boolean,
        phoneNumber: boolean,
        card: boolean,
        pin: boolean,
        storeSelected: boolean,
       }>({
        cpf: false,
        mail: false,
        phoneNumber: false,
        card: false,
        pin: false,
        storeSelected: false
      })
     const loaderStore = useLoaderStore();
  
      /** Validate CPF */
      watch(() => postObject.value.data?.cpf, () => {
        if(postObject.value.data?.cpf) {
          userAppCPF.value = { ...initUserAppCPF }
          isInvalidField.value.cpf = !campHandleCPF(postObject.value.data.cpf)
        } else {
          isInvalidField.value.cpf = false
          userAppCPF.value = { ...initUserAppCPF }
        }

        if(postObject.value.data?.cpf && postObject.value.data.cpf.length && !isInvalidField.value.cpf)
          handleGetCompetitorViewByCPF(postObject.value.data.cpf)
      })

      /** Validate email */
      // watch(() => postObject.value.data?.email, () => {
      //   if(postObject.value.data?.email) {
      //     isInvalidField.value.mail = !campHandleEmailVerification(postObject.value.data.email)
      //   } else isInvalidField.value.mail = false
      // })
  
      /** Validate phone number */
      watch(() => postObject.value.data?.tel, () => {
        if(postObject.value.data?.tel) {
          isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(postObject.value.data.tel)
        } else isInvalidField.value.phoneNumber = false
      })
  
      /** Reset warning */
      watch(() => postObject.value.data, () => {
        if(isRequiredField.value)
          isRequiredField.value = false
        if(isInvalidFieldWarning.value)
          isInvalidFieldWarning.value = false
      },
      { deep: true })
      
      const { showTimeAlert } = useAlert()
  
      async function onSubmitForm() {
        const { data } = postObject.value
        if(!data) {
          return showTimeAlert("Algo deu errado!", "error")
        }
        if(
          !data.cpf.length ||
          !data.name.length ||
          !data.tel.length ||
          // !data.email.length ||
          !data.birth_date?.length ||
          (!userAppCPF.value.data && !data.idStore.length)
        ) {
          return isRequiredField.value = true
        }
        if(!data.name.includes(' ')) return isRequiredNameComplete.value = true
        if(campHandleEveryoneIsTrue(isInvalidField.value)) {
          isInvalidFieldWarning.value = true
          return isRequiredField.value = true
        }
        loaderStore.open()
        try {
          const { data } = postObject.value
          if(data) {
            const tel = campHandleBrPhoneNumber(data.tel, "unformatted")
            const cpf = campHandleCPF(data.cpf, "unformatted")
            const card_number_check = data.card_number ? handleCreditCardNumber(data.card_number, "unformatted") : null
            const card_number = typeof card_number_check == "string" ? card_number_check : null
            if(typeof tel === "boolean" || typeof cpf === "boolean") {
              loaderStore.close()
              return showTimeAlert("Algo deu errado!", "error")
            }
            const card_pin = (typeof card_number === "string" && card_number.length > 0) ? data.card_pin : null
            const postData: IUserData = {
              ...data,
              tel,
              cpf,
            }
            await axios.post(`/api/postConsultant`, postData)
            showTimeAlert("Consultor cadastrado com Sucesso!")
            postObject.value.data = { ...initData }
            return router.push("/consultores")
          }
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
          loaderStore.close()
        }
      }
  
      async function getStores() {
        try {
          const company = companyStore.getCompany
          if(!(company && company.id))
            return
          const response = await axios.get(`/api/getStore?id_company=${ company.id || null }`)
          storeOptions.value = response.data.data
        } catch (error) {
          if(error instanceof AxiosError)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado!", "error")
        } finally { }
      }

      async function handleGetCompetitorViewByCPF(cpf: string) {
        try {
          userAppCPF.value = { ...initUserAppCPF }
          userAppCPF.value.isLoading = true
          const cpfOk = cpf.replace(/\D/g, '')
          if(cpfOk.length >= 11) {
            const response = await axios.get(`/api/getCompetitorViewByCPF?cpf=${ cpfOk }`)
            userAppCPF.value.data = {...response.data.data}
          }
        } catch (error) {

        } finally {
          if(userAppCPF.value.data) {
            postObject.value.data = userAppCPF.value.data
            const cpf = campHandleCPF(postObject.value.data.cpf, "formatted")
            postObject.value.data.cpf = typeof cpf === "string" ? cpf : postObject.value.data.cpf
            // const tel = campHandleBrPhoneNumber(postObject.value.data.tel, "formatted")
            // postObject.value.data.tel = typeof tel === "string" ? tel : postObject.value.data.tel
            userAppCPF.value.available = false
          } else {
            userAppCPF.value.available = true
            if(!postObject.value.data)
              return
            const cpf = postObject.value.data.cpf
            postObject.value.data = { ...initData }
            postObject.value.data.cpf = cpf
          }
          userAppCPF.value.isLoading = false
        }
      }

      watch(() => userAppCPF.value.available, () => userAppCPF.value.available && fieldNameRef.value?.focus())

      onMounted(async() => {
        await getStores()
      })
  
      return {
        postObject,
        isRequiredField,
        isInvalidFieldWarning,
        isInvalidField,
        onSubmitForm,
        authStore,
        storeOptions,
        isRequiredNameComplete,
        handleGetCompetitorViewByCPF,
        userAppCPF,
        fieldNameRef,
      }
    }
  })
  